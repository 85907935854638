<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="org-funds-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="fundsForm"
			style="margin-bottom: 30px"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="项目负责科室" prop="department">
						<Select
							v-model="formData.department"
							:disabled="entry === 'view'"
							filterable
							clearable
							placeholder="请选择项目负责科室"
						>
							<Option
								:value="depart.name"
								v-for="depart in departList"
								:key="depart.id"
								>{{ depart.name }}</Option
							>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目负责人" prop="piName">
						<Input
							:disabled="entry === 'view'"
							:maxlength="200"
							v-model.trim="formData.piName"
							placeholder="请输入项目负责人"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="手机号" prop="piMobile">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入手机号"
							:maxlength="11"
							v-model.trim="formData.piMobile"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目编号" prop="projectNo">
						<Input
							:disabled="entry === 'view'"
							v-model.trim="formData.projectNo"
							:maxlength="200"
							placeholder="请输入项目编号"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="项目名称" prop="projectName">
						<Input
							:disabled="entry === 'view'"
							type="textarea"
							:row="3"
							v-model.trim="formData.projectName"
							placeholder="请输入项目名称"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="项目类别" prop="projectType">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入项目类别"
							:maxlength="200"
							v-model.trim="formData.projectType"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="申办方（请填写全称）" prop="smo">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入申办方全称"
							v-model.trim="formData.smo"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="CRO">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入CRO"
							v-model.trim="formData.cro"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
			</Row>
			<!--入账-->
			<Row :gutter="32" v-if="type === 1">
				<Col span="12">
					<FormItem label="入账金额（元）" prop="amount">
						<InputNumber
							:disabled="entry === 'view'"
							placeholder="请输入入账金额（元）"
							:precision="2"
							:min="0"
							:max="999999999999999"
							:active-change="false"
							style="width: 100%"
							v-model="formData.amount"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="入账时间" prop="arrivalTime">
						<DatePicker
							type="date"
							:disabled="entry === 'view'"
							placeholder="请选择入账时间"
							clearable
							style="width: 100%"
							v-model="formData.arrivalTime"
							format="yyyy-MM-dd"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="入账原因" prop="reason">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入入账原因"
							v-model.trim="formData.reason"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="扣税税费（元）" prop="tax">
						<InputNumber
							:min="0"
							:disabled="entry === 'view'"
							placeholder="请输入扣税税费（元）"
							:precision="2"
							style="width: 100%"
							:max="999999999999999"
							:active-change="false"
							v-model="formData.tax"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="扣税税率" prop="taxRate">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入扣税税率"
							v-model.trim="formData.taxRate"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="入账信息" prop="information">
						<Select
							v-model="formData.information"
							:disabled="entry === 'view'"
							filterable
							clearable
							@on-change="handleChangeInfo"
							placeholder="请选择入账信息"
						>
							<Option
								:value="item.value"
								v-for="item in inAccountInfo"
								:key="item.value"
								>{{ item.label }}</Option
							>
						</Select>
					</FormItem>
				</Col>
				<Col span="12" v-if="formData.information === 7">
					<FormItem label="其他：请注明经费具体用途">
						<Input
							:disabled="entry === 'view'"
							placeholder="请注明经费具体用途"
							v-model.trim="formData.other"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
			</Row>
			<!--出账-->
			<Row :gutter="32" v-if="type === 2">
				<Col span="12">
					<FormItem label="出账金额（元）" prop="amount">
						<InputNumber
							:disabled="entry === 'view'"
							placeholder="请输入出账金额（元）"
							:precision="2"
							:min="0"
							:max="999999999999999"
							:active-change="false"
							style="width: 100%"
							v-model="formData.amount"
						></InputNumber>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="出账时间" prop="arrivalTime">
						<DatePicker
							type="date"
							:disabled="entry === 'view'"
							placeholder="请选择出账时间"
							clearable
							style="width: 100%"
							v-model="formData.arrivalTime"
							format="yyyy-MM-dd"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="出账原因" prop="reason">
						<Input
							:disabled="entry === 'view'"
							placeholder="请输入出账原因"
							v-model.trim="formData.reason"
							:maxlength="200"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row v-if="visible">
				<Col span="24">
					<!--:label="`上传${type === 1 ? '入' : '出'}账凭证扫描件`"-->
					<FormItem :label="fileLabel" prop="outlayAttachmentList">
						<Upload
							action="/"
							ref="upload"
							multiple
							:class="{ noDelBtn: entry === 'view' }"
							:disabled="entry === 'view'"
							:before-upload="file => handleBeforeUpload(file)"
							:on-error="onError"
							:on-remove="onRemove"
							:on-preview="handlePreview"
							:default-file-list="formData.outlayAttachmentList"
						>
							<Button :loading="uploadLoading" :disabled="entry === 'view'">{{
								uploadLoading ? "上传中..." : "上传附件"
							}}</Button>
						</Upload>
					</FormItem>
				</Col>
			</Row>
			<template v-if="type === 1">
				<h2 class="sub-title">开票信息</h2>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="开票公司名称" prop="invoiceCompany">
							<Input
								:disabled="entry === 'view'"
								placeholder="请输入开票公司名称"
								v-model.trim="formData.invoiceCompany"
								:maxlength="200"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="纳税人识别码" prop="invoiceTaxNo">
							<Input
								:disabled="entry === 'view'"
								placeholder="请输入纳税人识别码"
								v-model.trim="formData.invoiceTaxNo"
								:maxlength="200"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="开票公司地址" prop="invoiceAddress">
							<Input
								:disabled="entry === 'view'"
								placeholder="请输入开票公司地址"
								v-model.trim="formData.invoiceAddress"
								:maxlength="200"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="开票公司电话" prop="invoiceMobile">
							<Input
								:disabled="entry === 'view'"
								placeholder="请输入开票公司电话"
								v-model.trim="formData.invoiceMobile"
								:maxlength="200"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="开户银行" prop="invoiceBank">
							<Input
								:disabled="entry === 'view'"
								placeholder="请输入开户银行"
								v-model.trim="formData.invoiceBank"
								:maxlength="200"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="开户行账号" prop="invoiceBankAccount">
							<Input
								:disabled="entry === 'view'"
								placeholder="请输入开户行账号"
								v-model.trim="formData.invoiceBankAccount"
								:maxlength="200"
							/>
						</FormItem>
					</Col>
				</Row>
			</template>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button
				type="primary"
				@click="onSubmit"
				v-if="entry !== 'view'"
				:loading="uploadLoading"
				>确定</Button
			>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/funds"
import moment from "moment"
import { formDataToMD5 } from "@/utils/util"

const { apiGetFundsDetail, uploadFundsFile, addFunds, editFunds } = api

export default {
	name: "Add",
	props: {
		visible: {},
		projectId: {
			type: String,
			default: undefined
		},
		outlayId: {},
		entry: {},
		type: {},
		departList: {},
		projectInfo: {},
		inAccountInfo: {},
		apiUrlObj: {
			type: Object,
			default: () => ({ apiGetFundsDetail })
		}
	},
	data() {
		return {
			loading: false,
			uploadLoading: false,
			title: "",
			typeText: "",
			fileLabel: "",
			formData: {
				outlayAttachmentList: [],
				amount: null,
				tax: null
			},
			rules: {
				department: {
					required: true,
					message: "项目负责科室不能为空",
					trigger: "blur change"
				},
				piMobile: [
					{
						required: true,
						message: "手机号不能为空",
						trigger: "blur"
					},
					{
						...this.rules.mobileNumber,
						message: "手机号码格式错误",
						trigger: "blur"
					}
				],
				projectNo: {
					required: true,
					message: "项目编号不能为空",
					trigger: "blur"
				},
				smo: {
					required: true,
					message: "申办方不能为空",
					trigger: "blur"
				},
				outlayAttachmentList: {
					required: true,
					type: "array",
					message: "上传文件不能为空",
					trigger: "change blur"
				},
				piName: {
					required: true,
					message: "项目负责人不能为空",
					trigger: "blur"
				},
				projectName: {
					required: true,
					message: "项目名称不能为空",
					trigger: "blur"
				},
				projectType: {
					required: true,
					message: "项目类别不能为空",
					trigger: "blur"
				},
				amount: [
					{
						required: true,
						type: "number",
						message: "金额（元）不能为空",
						trigger: "change"
					}
				],
				arrivalTime: {
					required: true,
					type: "date",
					message: "时间不能为空",
					trigger: "blur"
				},
				reason: {
					required: true,
					message: "原因不能为空",
					trigger: "blur"
				},
				tax: [
					{
						required: true,
						type: "number",
						message: "扣税税费不能为空",
						trigger: "change"
					}
				],
				taxRate: {
					required: true,
					message: "扣税税率不能为空",
					trigger: "blur"
				},
				information: [
					{
						required: true,
						type: "number",
						message: "入账信息不能为空",
						trigger: "change"
					}
				],
				invoiceCompany: {
					required: true,
					message: "开票公司名称不能为空",
					trigger: "blur"
				},
				invoiceTaxNo: {
					required: true,
					message: "纳税人识别码不能为空",
					trigger: "blur"
				},
				invoiceAddress: {
					required: true,
					message: "开票公司地址不能为空",
					trigger: "blur"
				},
				invoiceMobile: {
					required: true,
					message: "开票公司电话不能为空",
					trigger: "blur"
				},
				invoiceBank: {
					required: true,
					message: "开户银行不能为空",
					trigger: "blur"
				},
				invoiceBankAccount: {
					required: true,
					message: "开户行账号不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.uploadLoading = false
				this.$refs.fundsForm && this.$refs.fundsForm.resetFields()
				this.formData = {
					...this.projectInfo,
					outlayAttachmentList: [],
					amount: null,
					tax: null
				}
				this.typeText = this.type === 1 ? "入账" : "出账"
				this.title = `新增${this.typeText}`
				this.fileLabel = `上传${this.typeText}凭证扫描件`
				if (this.outlayId) {
					this.title = `${this.entry === "view" ? "查看" : "修改"}${this.typeText}`
					this.$asyncDo(async () => {
						this.loading = true
						const params = {
							outlayId: this.outlayId
						}
						if (this.projectId) params.projectId = this.projectId
						const res = await this.apiUrlObj.apiGetFundsDetail(params)

						if (res) {
							let fileList = res.data ? res.data.outlayAttachmentList : []
							if (fileList.length) {
								fileList = fileList.map(item => {
									const file = { ...item }
									file.name = item.filename
									return file
								})
							}
							this.formData = {
								...res.data,
								outlayAttachmentList: fileList,
								arrivalTime:
									res.data && res.data.arrivalTime
										? new Date(res.data.arrivalTime)
										: ""
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		// 入账信息
		handleChangeInfo(val) {
			if (val !== 7) {
				this.formData.other = ""
			}
		},
		handleBeforeUpload(file) {
			this.uploadLoading = true
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, md5Str)
			})
			return false
		},
		// 上传文件
		uploadFile(file, md5Str) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				fd.append("md5", md5Str)
				const res = await uploadFundsFile(this.projectId, fd)
				if (res) {
					this.$Message.success("上传成功!")
					const newFile = Object.assign(file, { ...res.data })
					this.formData.outlayAttachmentList.push(newFile)
					this.$refs.fundsForm.validateField("outlayAttachmentList")
				}
				this.uploadLoading = false
			})
		},
		onError(error) {
			this.$Message.error(error)
		},
		onRemove(file) {
			this.formData.outlayAttachmentList =
				this.formData.outlayAttachmentList.filter(item => {
					if (file.id) {
						return item.id !== file.id
					}
					return item.uid !== file.uid
				})
			this.$refs.fundsForm.validateField("outlayAttachmentList")
		},
		// 下载
		handlePreview(file) {
			window.open(
				`${this.$baseUrl}/document/attachment/download/${file.attachmentId}?token=${this.$store.state.user.token}`
			)
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onSubmit() {
			this.$refs.fundsForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.outlayId) {
							res = await editFunds({
								...this.formData,
								type: this.type,
								classification: 1, // 1 机构 2 伦理
								arrivalTime: this.formData.arrivalTime
									? moment(this.formData.arrivalTime).format("YYYY-MM-DD")
									: "",
								projectId: this.projectId
							})
						} else {
							res = await addFunds({
								...this.formData,
								type: this.type,
								classification: 1,
								arrivalTime: this.formData.arrivalTime
									? moment(this.formData.arrivalTime).format("YYYY-MM-DD")
									: "",
								projectId: this.projectId
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style lang="less">
.org-funds-wrapper {
	margin-bottom: 20px;
	.ivu-select-input[disabled],
	.ivu-input[disabled],
	.ivu-input-number-input[disabled] {
		color: #333;
	}
	.ivu-input-number-input::placeholder {
		color: #c5c8ce;
	}
	.noDelBtn {
		.ivu-upload-list-remove {
			display: none;
		}
	}
	.sub-title {
		padding-bottom: 20px;
		font-weight: 700;
		font-size: 16px;
	}
}
</style>
